<template>
  <div class="view-wrapper overflow-y-auto">
    <div class="pt-8">
      <v-row class="justify-center">
        <v-card class="pl-2 pr-2 ma-2 elevation-5" style="width: 350px;">
          <v-card-title class="justify-center">
            {{ $localize('confirm_profile') }}
          </v-card-title>
          <v-card-text class="pl-2 pr-2 pb-0">
            <v-text-field v-model="item.firstName"
                          :label= "$localize('first_name')"
                          readonly
                          dense
            ></v-text-field>
            <v-text-field v-model="item.lastName"
                          :label= "$localize('last_name')"
                          readonly
                          dense
            ></v-text-field>
            <v-text-field v-model="item.middleName"
                          :label= "$localize('middle_name')"
                          readonly
                          dense
            ></v-text-field>
            <v-text-field v-model="item.phone"
                          :label= "$localize('telephone_number')"
                          prepend-inner-icon="mdi-phone"
                          readonly
                          dense
            ></v-text-field>
            <v-text-field v-model="item.email"
                          :label= "$localize('email')"
                          readonly
                          dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="py-6">
            <v-row class="justify-center">
              <v-btn color="success"
                     :disabled="disableButton"
                     @click="confirm"
              >
                <span class="pl-4 pr-4">{{$localize('confirm')}}</span>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-row>
    </div>
  </div>
</template>


<script>
export default {
  name: "VerifyAdmin",
  data() {
    return{
      item:{
        lastName: undefined,
        firstName: undefined,
        middleName: undefined,
        phone: undefined,
        email: undefined,
        photo: undefined,
      },
      isSending: false,
    }
  },

  computed:{
    disableButton(){
      if(!this.item.phone) return true
      else if(this.isSending) return true
      return false
    }
  },

  mounted(){
    this.init()
  },

  methods:{
    async init(){
      const res = await this.$fetch.get('api/user/get-by-code?code=' + this.$route.params.code)
      if(res) this.item = res
      else this.$store.dispatch('setError',{ show: true, type: 'error', message: 'error_try_again_later'})
    },

    async confirm(){
      this.isSending = true
      const res = await this.$fetch.post('api/agreement/admin/sign?username=' + this.$route.params.code)
      this.isSending = false
      if(res) this.$store.dispatch('setError',{ show: true, type: 'success', message: 'success'})
    }
  }
}
</script>